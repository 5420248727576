import React from "react";
import HomeLightAnimation from "../views/all-home-version/HomeLightAnimation";

const AllRoutes = () => {
  return (
    <HomeLightAnimation />
  );
};

export default AllRoutes;
